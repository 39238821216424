<template>
  <div>
    <div class="header"></div>
    <audio ref="shutter">
      <source src="../../assets/shutter.mp3" type="audio/mpeg" />
    </audio>
    <div class="container">
      <div class="row">
        <div class="col-md-12" style="color: #fff">
          <h4 class="text-center">Kebijakan Privasi</h4>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="card" style="border-radius: 15px">
            <div style="text-align: center; padding: 20px"></div>
            <div class="container">
              <div>
                <p style="text-align: justify">
                  PAKAGALI, dapat diakses dari www.pakagali.com, salah satu
                  prioritas utama kami adalah privasi pengunjung kami. Dokumen
                  Kebijakan Privasi ini berisi jenis informasi yang dikumpulkan
                  dan dicatat oleh PAKAGALI dan bagaimana kami menggunakannya.
                </p>

                <p style="text-align: justify">
                  Jika Anda memiliki pertanyaan tambahan atau memerlukan
                  informasi lebih lanjut tentang Kebijakan Privasi kami, jangan
                  ragu untuk menghubungi kami.
                </p>

                <h4>File Log</h4>

                <p style="text-align: justify">
                  PAKAGALI mengikuti prosedur standar menggunakan file log.
                  File-file ini mencatat pengunjung ketika mereka mengunjungi
                  situs web. Semua perusahaan hosting melakukan ini dan
                  merupakan bagian dari analitik layanan hosting. Informasi yang
                  dikumpulkan oleh file log mencakup alamat protokol internet
                  (IP), jenis peramban, Penyedia Layanan Internet (ISP), tanggal
                  dan waktu kunjungan, halaman rujukan/keluar, dan mungkin
                  jumlah klik. Informasi ini tidak terkait dengan informasi yang
                  dapat diidentifikasi secara pribadi. Tujuan dari informasi ini
                  adalah untuk menganalisis tren, mengelola situs, melacak
                  pergerakan pengguna di situs web, dan mengumpulkan informasi
                  demografis.
                </p>

                <h4>Cookies dan Web Beacon</h4>

                <p style="text-align: justify">
                  Seperti situs web lainnya, PAKAGALI menggunakan "cookies".
                  Cookies ini digunakan untuk menyimpan informasi termasuk
                  preferensi pengunjung, dan halaman di situs web yang diakses
                  atau dikunjungi oleh pengunjung. Informasi ini digunakan untuk
                  mengoptimalkan pengalaman pengguna dengan menyesuaikan konten
                  halaman web kami berdasarkan jenis peramban pengunjung
                  dan/atau informasi lainnya.
                </p>

                <h4>Kebijakan Privasi Pihak Ketiga</h4>

                <p style="text-align: justify">
                  Kebijakan Privasi PAKAGALI tidak berlaku untuk pengiklan atau
                  situs web lain. Dengan demikian, kami menyarankan Anda untuk
                  berkonsultasi dengan Kebijakan Privasi masing-masing server
                  iklan pihak ketiga untuk informasi lebih rinci. Ini mungkin
                  mencakup praktik dan petunjuk mereka tentang cara memilih
                  keluar dari opsi tertentu.
                </p>

                <p style="text-align: justify">
                  Anda dapat memilih untuk menonaktifkan cookies melalui opsi
                  peramban individual Anda. Untuk mengetahui informasi lebih
                  rinci tentang manajemen cookie dengan peramban web tertentu,
                  itu dapat ditemukan di situs web peramban masing-masing. Apa
                  Itu Cookies?
                </p>

                <h4>Informasi Anak-Anak</h4>

                <p style="text-align: justify">
                  Bagian lain dari prioritas kami adalah menambah perlindungan
                  untuk anak-anak saat menggunakan internet. Kami mendorong
                  orang tua dan wali untuk mengamati, berpartisipasi dalam,
                  dan/atau memantau dan membimbing aktivitas online mereka.
                </p>

                <p style="text-align: justify">
                  PAKAGALI tidak dengan sengaja mengumpulkan Informasi
                  Identifikasi Pribadi dari anak-anak di bawah usia 13 tahun.
                  Jika Anda merasa bahwa anak Anda memberikan informasi semacam
                  ini di situs web kami, kami sangat mendorong Anda untuk
                  menghubungi kami segera dan kami akan melakukan upaya terbaik
                  untuk segera menghapus informasi tersebut dari catatan kami.
                </p>

                <h4>Kebijakan Privasi Online</h4>

                <p style="text-align: justify">
                  Kebijakan Privasi ini hanya berlaku untuk aktivitas online
                  kami dan berlaku bagi pengunjung situs web kami terkait dengan
                  informasi yang mereka bagikan dan/atau kumpulkan di PAKAGALI.
                  Kebijakan ini tidak berlaku untuk informasi apa pun yang
                  dikumpulkan secara offline atau melalui saluran selain situs
                  web ini.
                </p>

                <h4>Persetujuan</h4>

                <p style="text-align: justify">
                  Dengan menggunakan situs web kami, Anda dengan ini menyetujui
                  Kebijakan Privasi kami dan menyetujui Syarat dan Ketentuannya.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- modal -->
    <b-modal v-model="modalVisible" @hide="beforeModalClose">
      <template #modal-header>
        <h5 class="modal-title">Disclaimer</h5>
        <button
          type="button"
          @click="closeModal"
          class="btn btn-danger btn-sm btn-icon btn-circle"
          data-dismiss="modal"
          aria-hidden="true"
        >
          ×
        </button>
      </template>
      <div>
        <div class="alert alert-warning">
          <p class="text-justify">
            Perlu diperhatikan bahwa kelalaian dalam memberikan informasi yang
            akurat dan lengkap akan mengakibatkan denda sebesar 100 kali lipat
            dari jumlah pembayaran yang seharusnya. Kami menegaskan bahwa adalah
            tanggung jawab penuh pelanggan untuk memastikan bahwa semua data
            yang diberikan telah diverifikasi dengan benar. Denda akan
            diberlakukan tanpa pengecualian sesuai dengan ketentuan yang
            berlaku.
          </p>
        </div>
      </div>
      <template #modal-footer>
        <button
          @click="postData"
          class="btn btn-block btn-warning"
          variant="primary"
          :disabled="loading"
        >
          <i v-if="loading" class="fas fa-circle-notch fa-spin"></i>
          Daftar
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import PageOptions from "../../config/PageOptions.vue";
import moment from "moment";

export default {
  data() {
    return {
      form: {
        customer_id: "",
        identity_number: "",
        npwrd: "",
        customer_name: "",
        customer_address: "",
        district: "",
        village: "",
        hamlet: "",
        neighbourhood: "",
        customer_categories: "",
        created_by: "",
        created_at: "",
        longitude: "",
        latitude: "",
        identity_status: "",
        employment_status: "",
        institutions: "",
      },
      loading: false,
      showUserAccess: true,
      formValidate: [],
      categoiresList: [],
      districtList: [],
      villageList: [],
      instansiList: [],

      isLoading: false,
      searchRemark: "",
      dataRemark: [],
      showPotensial: false,
      modalVisible: false,
      conditionMet: false,

      kameraFotoLokasi: null,
      kameraFotoSketsa: null,
      hasilFotoLokasi: [],
      hasilFotoSketsa: [],
      currentCamera: "environment",
      cameraOneOpen: false,
      cameraTwoOpen: false,
    };
  },
  mounted() {
    this.fetchCategories();
    this.fetchDistricts();
    this.fetchInstansi();
    this.getLocation();
  },
  created() {
    PageOptions.pageEmpty = true;
    document.body.className = "bg-white";
    moment.updateLocale("en", {
      months: [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ],
    });
  },
  beforeRouteLeave(to, from, next) {
    PageOptions.pageEmpty = false;
    document.body.className = "";
    next();
  },
  methods: {
    showModal() {
      this.modalVisible = true;
      this.conditionMet = false;
    },
    closeModal() {
      this.modalVisible = false;
      this.conditionMet = true;
    },
    beforeModalClose(evt) {
      if (!this.conditionMet) {
        evt.preventDefault();
      }
    },
    postData() {
      this.loading = true;
      if (this.form.identity_status == "") {
        this.formValidate.identity_status = "Pilih Jenis Identitas !";
        this.loading = false;
        this.closeModal();
      } else {
        const formData = new FormData();
        for (let key in this.form) {
          formData.append(key, this.form[key]);
        }
        if (this.hasilFotoSketsa.length > 0) {
          formData.append("image_house", this.hasilFotoSketsa[0].url);
        }
        axios
          .post("/v1/customers/registrer/", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              token: this.userToken,
            },
          })
          .then((response) => {
            if (response.data.validasi == false) {
              this.loading = false;
              this.formValidate = response.data.message;
            } else {
              this.loading = false;
              this.closeModal();
              Swal.fire({
                title: response.data.title,
                text: response.data.message,
                icon: response.data.icon,
                showCancelButton: false,
                showConfirmButton: false,
              });
              setTimeout(() => {
                Swal.close();
                if (response.data.status) {
                  this.$router.go(-1);
                }
              }, 700);
            }
          })
          .catch((error) => {
            console.log(error);
            this.error = error.message;
          });
      }
    },
    fetchCategories() {
      axios
        .get("/v1/customer_categories", {
          headers: {
            token: this.userToken,
          },
        })
        .then((response) => {
          this.categoiresList = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    fetchInstansi() {
      axios
        .get("/v1/government_agencies", {
          headers: {
            token: this.userToken,
          },
        })
        .then((response) => {
          this.instansiList = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    fetchDistricts() {
      axios
        .get("/v1/daerah/kecamatan/7271", {
          headers: {
            token: this.userToken,
          },
        })
        .then((response) => {
          this.districtList = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    fetchVillages(event) {
      axios
        .get("/v1/daerah/kelurahan/" + event.target.value, {
          headers: {
            token: this.userToken,
          },
        })
        .then((response) => {
          this.villageList = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          this.showPosition,
          this.showError
        );
      } else {
        alert("Geolokasi tidak didukung di browser ini.");
      }
    },
    showPosition(position) {
      this.form.latitude = position.coords.latitude;
      this.form.longitude = position.coords.longitude;
    },
    showError(error) {
      switch (error.code) {
        case error.PERMISSION_DENIED:
          alert("Akses ke lokasi ditolak oleh pengguna.");
          break;
        case error.POSITION_UNAVAILABLE:
          alert("Informasi lokasi tidak tersedia.");
          break;
        case error.TIMEOUT:
          alert("Waktu permintaan lokasi habis.");
          break;
        case error.UNKNOWN_ERROR:
          alert("Terjadi kesalahan yang tidak diketahui.");
          break;
      }
    },
    validasiNik() {
      this.formValidate.identity_number = "";
      if (this.form.identity_status == "1") {
        if (this.form.identity_number.length < 16) {
          this.formValidate.identity_number =
            "NIK Kurang " +
            (16 - this.form.identity_number.length) +
            " Digit !";
        } else if (this.form.identity_number.length > 16) {
          this.formValidate.identity_number =
            "NIK Kelebihan " +
            (this.form.identity_number.length - 16) +
            " Digit !";
        }
      } else if (this.form.identity_status == "2") {
        if (this.form.identity_number.length < 13) {
          this.formValidate.identity_number =
            "NIB Kurang " +
            (13 - this.form.identity_number.length) +
            " Digit !";
        } else if (this.form.identity_number.length > 13) {
          this.formValidate.identity_number =
            "NIB Kelebihan " +
            (this.form.identity_number.length - 13) +
            " Digit !";
        }
      }
    },
    async toggleCamera(param) {
      await this.stopCamera(param);
      this.currentCamera =
        this.currentCamera === "environment" ? "user" : "environment";
      await this.startCamera(param);
    },
    async startCamera(param) {
      try {
        if (param == 1) {
          const stream = await navigator.mediaDevices.getUserMedia({
            video: { facingMode: this.currentCamera },
          });
          this.$refs.kameraLokasi.srcObject = stream;
          this.kameraFotoLokasi = stream;
          this.cameraOneOpen = true;
          this.stopCamera(2);
        } else {
          const stream = await navigator.mediaDevices.getUserMedia({
            video: { facingMode: this.currentCamera },
          });
          this.$refs.kameraSketsa.srcObject = stream;
          this.kameraFotoSketsa = stream;
          this.cameraTwoOpen = true;
          this.stopCamera(1);
        }
      } catch (error) {
        console.error("Gagal membuka kamera:", error);
      }
    },
    stopCamera(param) {
      if (param == 1) {
        if (this.kameraFotoLokasi) {
          const tracks = this.kameraFotoLokasi.getTracks();
          tracks.forEach((track) => {
            track.stop();
            // track.enabled = false;
          });
          this.cameraOneOpen = false;
          this.kameraFotoLokasi = null;
        }
      }
      if (param == 2) {
        if (this.kameraFotoSketsa) {
          const tracks = this.kameraFotoSketsa.getTracks();
          tracks.forEach((track) => {
            track.stop();
            // track.enabled = false;
          });
          this.cameraTwoOpen = false;
          this.kameraFotoSketsa = null;
        }
      }
    },
    takePicture(param) {
      if (param === 1) {
        const video = this.$refs.kameraLokasi;
        const canvas = this.$refs.canvasLokasi;
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        // Gambar video ke dalam canvas
        const context = canvas.getContext("2d");
        context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

        // Ambil data gambar dari canvas dan tambahkan ke daftar
        const imageDataURL = canvas.toDataURL("image/png");
        const timestamp = Date.now();
        const imageName = `gambar_${timestamp}.png`;

        this.hasilFotoLokasi.push({ url: imageDataURL, name: imageName });
      }
      if (param === 2) {
        const video2 = this.$refs.kameraSketsa;
        const canvas2 = this.$refs.canvasSketsa;
        canvas2.width = video2.videoWidth;
        canvas2.height = video2.videoHeight;

        // Gambar video ke dalam canvas
        const context2 = canvas2.getContext("2d");
        context2.drawImage(video2, 0, 0, video2.videoWidth, video2.videoHeight);

        // Ambil data gambar dari canvas dan tambahkan ke daftar
        const imageDataURL = canvas2.toDataURL("image/png");
        const timestamp = Date.now();
        const imageName = `gambar_${timestamp}.png`;

        // Memeriksa apakah objek ditemukan berdasarkan indeks
        if (this.hasilFotoSketsa.length > 0) {
          // Mengganti nilai objek dengan nilai baru
          this.hasilFotoSketsa.splice(0, 1);
          this.hasilFotoSketsa.push({ url: imageDataURL, name: imageName });
        } else {
          // Jika objek tidak ditemukan, menambahkan objek baru ke array
          this.hasilFotoSketsa.push({ url: imageDataURL, name: imageName });
        }
      }

      const audio = this.$refs.shutter;
      if (audio.paused) {
        audio.play();
      } else {
        audio.pause();
      }

      // Pastikan ukuran canvas sesuai dengan ukuran video
    },
    deletePicture(index, param) {
      if (param == 1) {
        this.hasilFotoLokasi.splice(index, 1);
      } else {
        this.hasilFotoSketsa.splice(index, 1);
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
    if (this.mediaStream) {
      this.mediaStream.getTracks().forEach((track) => track.stop());
    }
  },
};
</script>
<style lang="css" scoped>
@font-face {
  font-family: "Saira";
  src: url("../../assets/font/saira.ttf") format("truetype");
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  background-color: #e94f07;
  /* color: #fff; */
  border-radius: 0px 0px 30px 30px;
  margin-bottom: -130px;
  z-index: 0;
}
.container {
  padding: 20px;
}
@import "/css/front-end/app.min.css";

.footers {
  background-color: #2b2b2b;
  color: #fff;
  position: sticky;
  top: 100%;
}
.copy {
  border-top: solid 0.15em #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: light;
  font-size: 14px;
  padding: 20px;
  margin: 0 3em;
}
.card-menu {
  background-color: #fdfdfd;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  min-height: 40vh;
}

.image-groups {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  border: 1px solid #ccc;
  margin-bottom: 5px;
  border-radius: 5px;
}

/* Image styling */
.image-container {
  display: flex;
  align-items: center;
  width: 30px;
  height: 30px;
}

.image-item {
  min-width: 30px;
  max-width: 30px;
  min-height: 30px;
  max-height: 30px;
  object-fit: cover;
  margin-right: 5px;
}

.tombol-hapus {
  float: right;
  color: rgb(255, 0, 0);
  border-radius: 3px;
  border: none;
  height: 30px;
  width: 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
}
.tombol-kirim {
  float: right;
  color: #fff;
  border-radius: 3px;
  border: none;
  height: 30px;
  width: 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0098fe;
}
</style>